
import DateTimePicker from "@evercam/shared/components/DateTimePicker"
import { useSnapshotStore } from "@/stores/snapshots"
import { useRecordingsStore } from "@/stores/recordings"
import { useEventsStore } from "@/stores/events"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"

export default {
  name: "RecordingsDateTimePicker",
  components: {
    DateTimePicker,
  },
  props: {
    disableEvents: {
      type: Boolean,
      default: false,
    },
    isLoadingLatestSnapshot: {
      type: Boolean,
      default: false,
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingDays: false,
      isLoadingHours: false,
      calendarDailyEvents: [],
      calendarHourlyEvents: [],
      availableDays: [],
      availableHours: [],
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useRecordingsStore,
      useEventsStore,
      useCameraStore
    ),
    timestamp() {
      return this.$attrs.value
    },
    maxDate() {
      if (this.isEdgeVideo) {
        return this.$moment(
          this.recordingsStore.exNvrMaxRecordingDate
        ).toISOString()
      }

      return this.$moment()
        .tz(this.cameraStore.selectedCameraTimezone)
        ?.format("YYYY-MM-DD")
    },
    minDate() {
      if (this.isEdgeVideo) {
        return this.$moment(
          this.recordingsStore.exNvrMinRecordingDate
        ).toISOString()
      }

      const minTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp(
          this.cameraStore.selectedCameraExid
        ) || this.cameraStore.selectedCamera?.createdAt

      return this.$moment(minTimestamp).toISOString()
    },
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
  },
  watch: {
    "eventsStore.eventsDailyCounts"(counts) {
      if (this.disableEvents || !Array.isArray(counts)) {
        return
      }

      this.calendarDailyEvents = counts.map((d) => d.day)
    },
    "eventsStore.eventsHourlyCounts"(counts) {
      if (this.disableEvents || !Array.isArray(counts)) {
        return
      }

      this.calendarHourlyEvents = counts.map((d) => d.hour)
    },
    isLoadingLatestSnapshot(val) {
      this.isLoadingDays = val
    },
    timestamp: {
      handler(value, oldValue) {
        if (!value || value === oldValue || this.isEdgeVideo) {
          return
        }
        this.setAvailableDays(this.$moment.tz(value, this.timezone))
        this.setAvailableHours(this.$moment.tz(value, this.timezone))
      },
      immediate: true,
    },
  },
  methods: {
    async onMonthChange(month) {
      if (!month) {
        this.availableDays = []

        return
      }

      this.isLoadingDays = true
      if (this.isEdgeVideo) {
        this.recordingsStore.populateAvailableExNvrDaysForMonth(
          month,
          this.cameraStore.selectedCameraTimezone
        )
      } else {
        await this.recordingsStore.fetchAvailableDaysForMonth(
          month,
          this.cameraStore.selectedCameraExid
        )
      }

      this.setAvailableDays(
        this.$moment.tz(month, this.timezone).add(1, "hour")
      )
      this.isLoadingDays = false
    },
    onMonthYearClick({ month, year }) {
      this.$emit("select-month-year", { month, year })
    },
    async onDayChange(date) {
      if (!date) {
        this.availableHours = []

        return
      }

      this.isLoadingHours = true

      if (this.isEdgeVideo && !this.recordingsStore.isXrayActive) {
        if (!this.recordingsStore.exNvrAvailableRecordingsIntervals?.length) {
          await this.recordingsStore.fetchExNvrAvailableRecordings()
        }
        await this.recordingsStore.populateAvailableExNvrHoursForDay(
          date,
          this.cameraStore.selectedCameraTimezone
        )
      } else {
        await this.recordingsStore.fetchAvailableHoursForDay(
          date,
          this.cameraStore.selectedCameraExid
        )
      }
      const selectedDay = this.$moment.tz(date, this.timezone)

      this.setAvailableDays(selectedDay)
      this.setAvailableHours(selectedDay)

      this.isLoadingHours = false
    },
    setAvailableDays(date) {
      const selectedMonth = date.format("YYYY-MM")

      this.availableDays =
        this.recordingsStore.availableDaysByMonth[selectedMonth] || []
      if (this.$permissions.project.has.go()) {
        const maxDate = this.$moment(
          this.snapshotStore.latestSnapshotTimestamp(
            this.cameraStore.selectedCameraExid
          )
        ).tz(this.timezone)

        const oneMonthBefore = maxDate.clone().subtract(1, "month")

        this.availableDays = this.availableDays.filter((day) =>
          oneMonthBefore.isBefore(
            this.$moment(`${selectedMonth}-${day}`, "YYYY-MM-DD")
          )
        )
      }
    },
    setAvailableHours(date) {
      const selectedDay = date.format("YYYY-MM-DD")

      this.availableHours =
        this.recordingsStore.availableHoursByDay[selectedDay] || []
    },
  },
}
